import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import pickHistoryApi from 'store/services/pickHistory';
import { thunkWrapper } from 'store/thunk';
import { AppDispatch } from '..';

export interface IPickHistoryReq {
  game?: string;
  market?: string;
  price?: string;
  active?: boolean;
  roundId?: number;
  page?: string;
  limit?: string;
  dateFrom?: string;
  dateTo?: string;
  timezone?: string;
}

export interface IPickHistoryRes {
  picks: IPickHistory[];
  aiAccuracy: number;
  isHistoryEnabledForUser: boolean;
  total: number;
}

export interface IUpdateAvailabilityReq {
  isAvailable: boolean;
}

export interface IPickHistory {
  gameId: number;
  isActive: boolean;
  marketId: number;
  originalResult: string;
  pickGeneratorId: number;
  priceId: number;
  rate: number;
  result: string;
  resultMatch: string;
  roundId: number;
  startIn: string;
  gameResult: string;
}

export interface IDeleteHistory {
  id: number;
}

export interface ICurrentMessage {
  success: string;
  error: string;
}

const initialState: {
  current: {
    success: boolean;
    loading: boolean;
    message: ICurrentMessage;
  };
  availability: {
    status: boolean;
    loading: boolean;
  };
  isHistoryEnabledForUser: boolean;
  history: IPickHistory[];
  accuracyRate: number;
  total: number;
  loading: boolean;
} = {
  current: {
    success: false,
    loading: false,
    message: {
      success: '',
      error: '',
    },
  },
  availability: {
    status: false,
    loading: false,
  },
  isHistoryEnabledForUser: false,
  history: [],
  accuracyRate: 0,
  total: 0,
  loading: false,
};

export const getPickHistory = createAsyncThunk<
  IPickHistoryRes,
  IPickHistoryReq,
  { dispatch: AppDispatch }
>('pickHistory/get', (payload) => thunkWrapper(payload, pickHistoryApi.get));

export const deletePickHistory = createAsyncThunk<
  unknown,
  IDeleteHistory,
  { dispatch: AppDispatch }
>('pickHistory/delete', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, pickHistoryApi.delete, rejectWithValue)
);

export const updateAvailability = createAsyncThunk<
  boolean,
  IUpdateAvailabilityReq,
  { dispatch: AppDispatch }
>('pickHistory/availability', (payload) =>
  thunkWrapper(payload, pickHistoryApi.updateAvailability)
);

export const pickHistorySlice = createSlice({
  name: 'pickHistory',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCurrentMessages: (state, action) => {
      const { payload } = action;
      state.current.message.success = payload?.success;
      state.current.message.error = payload?.error;
    },
    resetCurrent: (state) => {
      state.current = {
        success: false,
        loading: false,
        message: {
          success: '',
          error: '',
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPickHistory.fulfilled, (state, action) => {
      const { payload } = action;
      state.history = payload?.picks;
      state.accuracyRate = Number(payload?.aiAccuracy?.toFixed(1)) || 0;
      state.availability.status = payload?.isHistoryEnabledForUser;
      state.total = payload?.total;
      state.loading = false;
    });
    builder.addCase(getPickHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPickHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAvailability.fulfilled, (state, action) => {
      const { payload } = action;
      console.log({payload});
      state.availability.status = payload;
      state.availability.loading = false;
    });
    builder.addCase(updateAvailability.pending, (state) => {
      state.availability.loading = true;
    });
    builder.addCase(updateAvailability.rejected, (state) => {
      state.availability.loading = false;
    });
    builder.addCase(deletePickHistory.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(deletePickHistory.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(deletePickHistory.rejected, (state) => {
      state.current.loading = false;
    });
  },
});

export const { setCurrentMessages, reset, resetCurrent } =
  pickHistorySlice.actions;
export default pickHistorySlice.reducer;
