import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import moment from 'moment';
import { timezones } from 'constants/index';
import { setTimezone } from 'store/slices/global';
import { selectTimezone } from 'store/selectors/global';
import { setStorageData } from 'utils/localStorage';
import { AppDispatch } from 'store/index';

const LiveTime: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [time, setTime] = useState(new Date());

  const timezone = useSelector(selectTimezone);
  const setTimeZone = (payload: number) => dispatch(setTimezone(payload));

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='LiveTime'>
      <div className='LiveTime-Time'>
        <b>{moment(time).utcOffset(timezone).format('DD.MM.YY HH:mm:ss')}</b>
      </div>
      <Select
        className='LiveTime-Timezone'
        value={timezone}
        onChange={(val): void => {
          setTimeZone(val);
          setStorageData('timezone', { timezone: val });
        }}
        options={timezones.map(({ label, value }) => ({
          label,
          value,
        }))}
        size='small'
      />
    </div>
  );
};

export default LiveTime;
