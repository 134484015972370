import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userManagementApi from 'store/services/userManagement';
import { thunkWrapper } from 'store/thunk';
import { AppDispatch } from '..';

export interface IUserReq {
  type?: string;
  active?: boolean;
  filter?: string;
  page?: string;
  limit?: string;
}

export interface IUserRes {
  users: IUser[];
  count: number;
}

export interface IUser {
  id: number;
  role: string;
  username: string;
  password: string;
  creationDate: string;
  experationDate: string;
  isEnabled: boolean;
  isNeedToChangePassword: boolean;
  picks: unknown;
  whiteListedIpAddresses: IIpAddresses[];
  passwordTokens: string[];
  note: string;
}

export interface IIpAddresses {
  ipAddress: string;
}

export interface ICreateUserReq {
  username: string;
  password: string;
  expiresAt: string;
}

export interface IUpdateUserReq {
  id: number;
  key: string;
  value: unknown;
  ipNonRestricted?: boolean;
}

export interface ICurrentMessage {
  success: string;
  error: string;
}

const initialState: {
  current: {
    success: boolean;
    loading: boolean;
    message: ICurrentMessage;
  };
  users: IUser[];
  total: number;
  loading: boolean;
} = {
  current: {
    success: false,
    loading: false,
    message: {
      success: '',
      error: '',
    },
  },
  users: [],
  total: 0,
  loading: false,
};

export const getUsers = createAsyncThunk<
  IUserRes,
  IUserReq,
  { dispatch: AppDispatch }
>('userManagement/get', (payload) =>
  thunkWrapper(payload, userManagementApi.get)
);

export const createUser = createAsyncThunk<
  unknown,
  ICreateUserReq,
  { dispatch: AppDispatch }
>('userManagement/createUser', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, userManagementApi.createUser, rejectWithValue)
);

export const updateUser = createAsyncThunk<
  unknown,
  IUpdateUserReq,
  { dispatch: AppDispatch }
>('userManagement/updateUser', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, userManagementApi.updateUser, rejectWithValue)
);

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCurrentMessages: (state, action) => {
      const { payload } = action;
      state.current.message.success = payload?.success;
      state.current.message.error = payload?.error;
    },
    resetCurrent: (state) => {
      state.current = {
        success: false,
        loading: false,
        message: {
          success: '',
          error: '',
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { payload } = action;
      state.users = payload?.users;
      state.total = payload?.count;
      state.loading = false;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(createUser.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.current.loading = false;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.current.loading = false;
    });
  },
});

export const { setCurrentMessages, reset, resetCurrent } =
  userManagementSlice.actions;
export default userManagementSlice.reducer;
