import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { setLocalization } from 'store/slices/global';
import { selectLocalization } from 'store/selectors/global';

import { ReactComponent as GB } from 'assets/flags/gb.svg';
import { ReactComponent as KR } from 'assets/flags/kr.svg';
import { AppDispatch } from 'store/index';

const size: { [key: string]: ReactElement } = {
  EN: <GB />,
  KR: <KR />,
};

const LanguageSwitcher: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const localization = useSelector(selectLocalization);

  const setLanguage = (payload: string) => dispatch(setLocalization(payload));

  return (
    <Select
      className='HeaderMain-Localization'
      value={localization}
      onChange={(value) => setLanguage(value)}
      suffixIcon={null}
      variant='borderless'
      // open={true}
      labelRender={({ value }) => {
        const code = `${value}`;
        return (
          <div className='HeaderMain-Localization-Select'>
            {size[code]} <p>{code}</p>
          </div>
        );
      }}
      dropdownRender={(v) => (
        <div className='HeaderMain-Localization-Item'>{v}</div>
      )}
      size='small'
      options={[
        {
          value: 'EN',
          label: (
            <div className='HeaderMain-Localization-Item'>
              <GB /> <p>EN</p>
            </div>
          ),
        },
        {
          value: 'KR',
          label: (
            <div className='HeaderMain-Localization-Item'>
              <KR /> <p>KR</p>
            </div>
          ),
        },
      ].filter((l) => l.value !== localization)}
    />
  );
};

export default LanguageSwitcher;
