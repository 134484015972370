import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import pickApi from 'store/services/picks';
import { thunkWrapper } from 'store/thunk';
import { AppDispatch } from '..';

type Price =
  | 'Odd'
  | 'Even'
  | 'Over'
  | 'Under'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6';

export interface IGetPicksTemplateRes {
  pickResults: ITemplate[];
}

export interface IGetConfigurationsReq {
  params: { gameName: string };
}

export interface IGetConfigurationsRes {
  pickConfigurations: IConfigurations[];
}

export interface IConfigurations {
  id: number;
  aiPick: IAIPick;
  upcomingAiPick: IUpcomingAIPick;
  lastRoundId: number;
  everyNewRound: boolean;
  isEnabled: boolean;
  onceEveryEnabled: boolean;
  onceEveryFrom: number;
  onceEveryTo: number;
  pickCount: number;
  pickCountEnabled: boolean;
  rateEnabled: boolean;
  rateFrom: number;
  rateTo: number;
  gameId: number;
  manualPicks: IManualPick[];
  marketId: number;
  priceId: number;
  // upComingManualPicks: [];
}

export interface ICreatePickReq {
  gameId: number;
  marketId: number;
  priceId: number;
  aiPick: IAIPick;
}

export interface IAIPick {
  hasAiPick: boolean;
  rateEnabled: boolean;
  rateFrom: number;
  rateTo: number;
  onceEveryFrom: number;
  onceEveryTo: number;
  onceEveryEnabled: boolean;
  everyNewRound: boolean;
  pickCountEnabled: boolean;
  pickCount: number;
  lastRoundId?: number;
  isEnabled: boolean;
  startIn?: string;
  userPick?: IUserPick;
}

export interface IUpcomingAIPick {
  gameId: number;
  isActive: boolean;
  marketId: number;
  priceId: number;
  rate: number;
  result: string;
  roundId: number;
  startIn: string;
}

export interface IUpcomingManualPick {
  gameId: number;
  isActive: boolean;
  marketId: number;
  priceId: number;
  rate: number;
  result: string;
  roundId: number;
  startIn: string;
}

export interface IManualPick {
  id: number;
  rate: number;
  result: string;
  roundId: number;
  startIn: string;
  userPick: IUserPick;
}

export interface IUserPick {
  gameId: number;
  id: number;
  isActive: boolean;
  isAiPick: boolean;
  marketId: number;
  priceId: number;
  rate: number;
  result: string;
  resultMatch: boolean;
  roundId: number;
  startIn: string;
}

export interface ICreatePickRes {}

export interface IUpdatePickReq extends ICreatePickReq {}

export interface IUpdatePickRes {}

export interface ICreateManualPickReq {
  gameId: number;
  marketId: number;
  priceId: number;
  pickConfigurationId: number;
  upcomingRoundId: number;
  rate: number;
  result: string;
}

export interface ICreateManualPickRes extends IUpcomingManualPick {}

export interface IDeleteManualPickReq {
  id: number;
}

export interface IDeleteManualPickRes {}

export interface ITemplate {
  gameId: number;
  gameName: string;
  market: IMarket[];
}

export interface IMarket {
  marketId: number;
  name: string;
  prices: IPrice[];
}

export interface IPrice {
  configurationId: number;
  lastRoundId: number;
  priceId: number;
  name: string;
  values: Price[];
  handicapValue: number;
  aiPick: IAIPick;
  upComingAiPick: IUpcomingAIPick;
  manualPicks: IManualPick[];
  upComingManualPicks: IUpcomingManualPick[];
  userPick: IUserPick;
}

export interface ICurrentMessage {
  success: string;
  error: string;
}

const initialState: {
  templates: ITemplate[];
  tempLoading: boolean;
  tempLoaded: boolean;
  configurations: IConfigurations[];
  configLoading: boolean;
  configLoaded: boolean;
  current: {
    success: boolean;
    loading: boolean;
    message: ICurrentMessage;
  };
} = {
  templates: [],
  tempLoading: false,
  tempLoaded: false,
  configurations: [],
  configLoading: false,
  configLoaded: false,
  current: {
    success: false,
    loading: false,
    message: {
      success: '',
      error: '',
    },
  },
};

export const getPicksTemplate = createAsyncThunk<
  IGetPicksTemplateRes,
  unknown,
  { dispatch: AppDispatch }
>('picksTemplate/get', (payload) => thunkWrapper(payload, pickApi.getTemplate));

export const getPicksConfigurations = createAsyncThunk<
  IGetConfigurationsRes,
  IGetConfigurationsReq,
  { dispatch: AppDispatch }
>('picksConfigurations/get', (payload) =>
  thunkWrapper(payload, pickApi.getConfigurations)
);

export const createPick = createAsyncThunk<
  ICreatePickRes,
  ICreatePickReq,
  { dispatch: AppDispatch }
>('pick/create', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, pickApi.createPick, rejectWithValue)
);

export const updatePick = createAsyncThunk<
  IUpdatePickRes,
  IUpdatePickReq,
  { dispatch: AppDispatch }
>('pick/update', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, pickApi.updatePick, rejectWithValue)
);

export const createManualPick = createAsyncThunk<
  ICreateManualPickRes,
  ICreateManualPickReq,
  { dispatch: AppDispatch }
>('manualPick/create', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, pickApi.createManualPick, rejectWithValue)
);

export const deleteManualPick = createAsyncThunk<
  IDeleteManualPickRes,
  IDeleteManualPickReq,
  { dispatch: AppDispatch }
>('manualPick/delete', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, pickApi.deleteManualPick, rejectWithValue)
);

export const getUserPicks = createAsyncThunk<
  IUserPick[],
  null,
  { dispatch: AppDispatch }
>('userPicks/get', (payload) => thunkWrapper(payload, pickApi.getUserPicks));

export const pickSlice = createSlice({
  name: 'picks',
  initialState,
  reducers: {
    setCurrentMessages: (state, action) => {
      const { payload } = action;
      state.current.message.success = payload?.success;
      state.current.message.error = payload?.error;
    },
    reset: () => {
      return initialState;
    },
    resetCurrent: (state) => {
      state.current = {
        success: false,
        loading: false,
        message: {
          success: '',
          error: '',
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPicksTemplate.fulfilled, (state, action) => {
      const { payload } = action;
      state.templates = payload?.pickResults;
      state.tempLoading = false;
      state.tempLoaded = true;
    });
    builder.addCase(getPicksTemplate.pending, (state, action) => {
      state.tempLoading = true;
    });
    builder.addCase(getPicksConfigurations.fulfilled, (state, action) => {
      const { payload } = action;
      const templates = state.templates;
      templates.forEach((t) => {
        t.market.forEach((m) => {
          m.prices.forEach((p) => {
            const pick = payload.pickConfigurations.find(
              (g) =>
                g.gameId === t.gameId &&
                g.marketId === m.marketId &&
                g.priceId === p.priceId
            );
            p.configurationId = pick?.id || 0;
            p.lastRoundId = pick?.lastRoundId || 0;
            p.aiPick = pick?.aiPick
              ? { ...pick?.aiPick, hasAiPick: true }
              : {
                  hasAiPick: false,
                  rateEnabled: false,
                  rateFrom: 0,
                  rateTo: 0,
                  onceEveryFrom: 0,
                  onceEveryTo: 0,
                  onceEveryEnabled: false,
                  everyNewRound: false,
                  pickCountEnabled: false,
                  pickCount: 0,
                  lastRoundId: 0,
                  isEnabled: false,
                  startIn: '',
                  userPick: {
                    gameId: 0,
                    id: 0,
                    isActive: false,
                    isAiPick: false,
                    marketId: 0,
                    priceId: 0,
                    rate: 0,
                    result: '',
                    resultMatch: false,
                    roundId: 0,
                    startIn: '',
                  },
                };
            p.manualPicks = pick?.manualPicks || [];
          });
        });
      });
      state.templates = templates;
      state.configLoading = false;
      state.configLoaded = true;
    });
    builder.addCase(getPicksConfigurations.pending, (state, action) => {
      state.configLoading = true;
    });
    builder.addCase(createPick.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(createPick.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(createPick.rejected, (state) => {
      state.current.loading = false;
    });
    builder.addCase(updatePick.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(updatePick.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(updatePick.rejected, (state) => {
      state.current.loading = false;
    });
    builder.addCase(createManualPick.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(createManualPick.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(createManualPick.rejected, (state) => {
      state.current.loading = false;
    });
    builder.addCase(deleteManualPick.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(deleteManualPick.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(deleteManualPick.rejected, (state) => {
      state.current.loading = false;
    });
    builder.addCase(getUserPicks.fulfilled, (state, action) => {
      const { payload } = action;
      const templates = state.templates;
      templates.forEach((t) => {
        t.market.forEach((m) => {
          m.prices.forEach((p) => {
            const pick = payload.find(
              (g) =>
                g.gameId === t.gameId &&
                g.marketId === m.marketId &&
                g.priceId === p.priceId
            );
            p.userPick = pick?.id
              ? pick
              : {
                  gameId: 0,
                  id: 0,
                  isActive: false,
                  isAiPick: false,
                  marketId: 0,
                  priceId: 0,
                  rate: 0,
                  result: '',
                  resultMatch: false,
                  roundId: 0,
                  startIn: '',
                };
          });
        });
      });
      state.templates = templates;
      state.configLoading = false;
      state.configLoaded = true;
    });
    builder.addCase(getUserPicks.pending, (state, action) => {
      state.configLoading = true;
    });
  },
});

export const { setCurrentMessages, reset, resetCurrent } = pickSlice.actions;
export default pickSlice.reducer;
