import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSignOut } from 'store/slices/user';
import { toggleMenu } from 'store/slices/global';
import { selectUser } from 'store/selectors/user';
import { selectMenu } from 'store/selectors/global';
import { Menu, Grid } from 'antd';
import type { MenuProps } from 'antd';
import { IRoutes, getRoutes } from 'constants/routes';
import clsx from 'clsx';

import { ReactComponent as ReactLogo } from 'assets/app_logo_w.svg';
import { AppDispatch } from 'store/index';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;
type MenuItem = Required<MenuProps>['items'][number];
type IMenuItemCustom = MenuItem & {
  className: string;
};
function getItem({
  label,
  key,
  icon,
  children,
  type,
  className,
}: {
  label: React.ReactNode;
  key?: React.Key | null;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: 'group';
  className?: string;
}): IMenuItemCustom {
  return {
    key,
    icon,
    children,
    label,
    type,
    className,
  } as IMenuItemCustom;
}

const items = (routes: any, location: any) => {
  const menuItems = routes.map((r: IRoutes) => {
    if (r.isMenuItem) {
      return r.subRoutes?.length
        ? getItem({
            label: !r.subRoutes.length ? (
              <Link to={r.path} children={r.label} />
            ) : (
              r.label
            ),
            key: r.index,
            icon: r.icon,
            children: items(r.subRoutes, location),
            className: clsx('MenuMain-Menu-Item', r.className),
          })
        : getItem({
            label: r.path && location.pathname !== r.path ? <Link to={r.path} children={r.label} /> : r.label,
            key: r.index,
            icon: r.icon,
            className: clsx('MenuMain-Menu-Item', r.className),
          });
    }
  });
  return menuItems;
};

const MainMenu: React.FC = (
) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const screen = useBreakpoint();
  const { t } = useTranslation();
  const profile = useSelector(selectUser);
  const collapsed = useSelector(selectMenu);
  const userRole = profile?.role;

  const signOut = (payload: { userName: string }) =>
    dispatch(userSignOut(payload));
  const toggleM = (payload: boolean) => dispatch(toggleMenu(payload));

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      signOut({ userName: profile?.userName });
    }
    screen?.xs && !collapsed && toggleM(!collapsed);
  };

  useEffect(() => {
    !screen?.xs && toggleM(false);
    screen?.xs && toggleM(true);
  }, [screen?.xs])

  return (
    <div
      className={clsx('MenuMain', screen?.xs && collapsed && 'MenuMain_hide')}
    >
      <div className='MenuMain-Section'>
        <ReactLogo className='MenuMain-Section-Logo' />
        <div className='MenuMain-Section-Content'>
          <p><b>{t('menu-title-1')}</b></p>
          <p><b>{t('menu-title-2')}</b></p>
        </div>
      </div>
      <Menu
        className={clsx('MenuMain-Menu')}
        theme='dark'
        onClick={onClick}
        // defaultOpenKeys={['sub1']}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={location.pathname.split('/').map((l) => `/${l}`)}
        inlineCollapsed={collapsed}
        mode='inline'
        items={[...items(getRoutes(true, userRole, t), location)]}
      />
    </div>
  );
};

export default MainMenu;
