import { Outlet } from 'react-router-dom';
import LanguageSwitcher from 'components/templates/LanguageSwitcher';
import { ReactComponent as ReactLogo } from 'assets/app_logo.svg';

const AuthLayout = () => {
  return (
    <main
      className='AuthMain'
      children={
        <div className='Body'>
          <div className='LanguageSwitcher'>
            <LanguageSwitcher />
          </div>
          <div className='Content'>
            <div className='Logo'>
              <ReactLogo className='Logo' />
            </div>
            <Outlet />
          </div>
        </div>
      }
    />
  );
};

export default AuthLayout;
