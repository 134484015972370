import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userSlice from './slices/user';
import globalSlice from './slices/global';
import statisticsSlice from './slices/statistics';
import userManagementSlice from './slices/userManagement';
import pickSlice from './slices/picks';
import pickHistorySlice from './slices/pickHistory';

const persistConfig = {
  storage,
  key: 'root',
  prefix: '',
  whitelist: ['user'],
};

const reducers = combineReducers({
  global: globalSlice,
  user: userSlice,
  statistics: statisticsSlice,
  userManagement: userManagementSlice,
  picks: pickSlice,
  pickHistory: pickHistorySlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
