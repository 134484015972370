import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import statisticsApi from 'store/services/statistics';
import { thunkWrapper } from 'store/thunk';
import { AppDispatch } from '..';

export interface IStatisticsReq {
  dateFrom: string;
  dateTo: string;
  timezone?: string;
  game: string;
}

export interface IStatisticsNewReq {
  dateFrom: string;
  dateTo: string;
  timezone?: string;
  game: string;
  market: string;
  price: string;
  patternLength?: number;
}

export interface IStatisticsNewRes {
  marketStatistics: IMarketStatisticsN;
  hourlyStatistics: IHourlyStatistics;
  countiniousWining: IContinuousWining;
  winRateOverTime: IWinRateOverTime[];
  paternAnalyzis: null;
  ballStatistics: IBallsAnalyze;
}

export interface IMarketStatisticsN {
  dataCount: number;
  gameName: string;
  marketName: string;
  priceName: string;
  statistics: IStatisticN[];
}

export interface IStatisticN {
  percentage: number;
  percent?: number;
  marketName: string;
  ball: string;
  count: number;
  priceDate?: string;
  priceName?: string;
  date?: string;
  roundId?: number;
}

export interface IHourlyStatistics {
  marketName: string;
  priceName: string;
  statistics: IStatisticN[];
}

export interface IContinuousWining {
  maxOdd: number;
  maxEven: number;
  maxOver: number;
  maxUnder: number;
  maxBalls: { count: number }[];
  statistics: IStatisticN[];
}

export interface IPatternAnalyze {
  patternDatas: IPatternData[];
  statistics: [];
}

export interface IPatternData {
  groupDate: string;
  rounds: { id: number; result: string }[];
  nextResult: string;
  nextResultRoundId: number;
}

export interface IBallsAnalyze {
  ballPositionStatistics: IBallData[];
}

export interface IBallData {
  percent: number;
  position: number;
}

export interface IWinRateOverTime {
  statistics: { data: IStatisticN[] };
}

export interface IStatisticsRes {
  marketStatistics: IMarketStatistics[];
}
interface IMarketStatistics {
  gameName: string;
  marketName: string;
  priceName: string;
  statistics: IStatistics[];
  markets: { prices: IStatistics }[];
}
export interface IStatistics {
  marketName: string;
  prices: {
    count: number;
    marketName?: string;
    ball?: string;
    percentage: number;
  }[];
}

const initialState: {
  markets: IMarketStatistics[];
  cards: IMarketStatistics[];
  donut: IStatisticN[];
  hourly: IStatisticN[];
  continuousWinning: IContinuousWining;
  winRateOverTime: { data: IStatisticN[] }[];
  patternAnalyze: IPatternAnalyze;
  positionAnalyze: IBallsAnalyze;
  loading: boolean;
  loaded: boolean;
  hourlyLoading: boolean;
  continueWinningLoading: boolean;
  winRateLoading: boolean;
  patternLoading: boolean;
  ballLoading: boolean;
} = {
  markets: [],
  cards: [],
  donut: [],
  hourly: [],
  continuousWinning: {
    maxOdd: 0,
    maxEven: 0,
    maxOver: 0,
    maxUnder: 0,
    maxBalls: [{ count: 0 }],
    statistics: [],
  },
  winRateOverTime: [{ data: [] }],
  patternAnalyze: { patternDatas: [], statistics: [] },
  positionAnalyze: { ballPositionStatistics: []},
  loading: false,
  loaded: false,
  hourlyLoading: false,
  continueWinningLoading: false,
  winRateLoading: false,
  patternLoading: false,
  ballLoading: false,
};

export const getStatistics: any = createAsyncThunk<
  IStatisticsRes,
  IStatisticsReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/get', (payload) => thunkWrapper(payload, statisticsApi.get));

export const getStatisticsNew: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getNew', (payload) =>
  thunkWrapper(payload, statisticsApi.getNew)
);

export const getHourlyStatistics: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getHourlyStatistics', (payload) =>
  thunkWrapper(payload, statisticsApi.getHourly)
);

export const getContinuousWinStatistics: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getContinuousWinStatistics', (payload) =>
  thunkWrapper(payload, statisticsApi.getContinuousWin)
);

export const getWinRateStatistics: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getWinRateStatistics', (payload) =>
  thunkWrapper(payload, statisticsApi.getWinRate)
);

export const getPatternStatistics: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getPatterns', (payload) =>
  thunkWrapper(payload, statisticsApi.getPatterns)
);

export const getPositionStatistics: any = createAsyncThunk<
  IStatisticsNewRes,
  IStatisticsNewReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('statistics/getPositions', (payload) =>
  thunkWrapper(payload, statisticsApi.getPositions)
);

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      const top3 = payload?.marketStatistics?.filter(
        (m: IMarketStatistics) => m.marketName.toLowerCase() === 'top 3 sum'
      );
      const mTop3 = {
        ...top3?.[0],
        markets: top3
          ?.map((m: IMarketStatistics) => {
            return { marketName: m.priceName, prices: m.statistics };
          })
          .filter((m: IStatistics) => m.prices.length),
        // .sort((a: IStatistics, b: IStatistics) => a.marketName < b.marketName),
      };
      const ball = payload?.marketStatistics?.filter(
        (m: IMarketStatistics) => m.marketName.toLowerCase() === '1st ball'
      );
      const mBall: IMarketStatistics = {
        ...ball?.[0],
        markets: ball
          ?.map((m: IMarketStatistics) => {
            return { marketName: m.priceName, prices: m.statistics };
          })
          ?.filter((m: IStatistics) => m.prices.length),
        // .sort((a: IStatistics, b: IStatistics) => a.marketName < b.marketName),
      };
      const championBall = payload?.marketStatistics.filter(
        (m: IMarketStatistics) => m.marketName.toLowerCase() === 'championball'
      );
      const mChampionBall = {
        ...championBall?.[0],
        markets: championBall
          ?.map((m: IMarketStatistics) => {
            return { marketName: m.priceName, prices: m.statistics };
          })
          ?.filter((m: IStatistics) => m.prices.length),
      };
      state.cards = [mBall, mTop3, mChampionBall];
      state.markets = payload?.marketStatistics;
      state.loading = false;
      state.loaded = true;
    });
    builder.addCase(getStatistics.pending, (state, action) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(getStatisticsNew.fulfilled, (state, action) => {
      const { payload } = action;
      state.donut = payload?.marketStatistics?.statistics;
      // state.hourly = payload?.hourlyStatistics?.statistics;
      // state.continuousWinning = payload?.countiniousWining;
      // state.winRateOverTime = payload?.winRateOverTime?.statistics;
      // state.patternAnalyze = payload?.paternAnalyzis;
      // if (!state.hourly.length) {
      //   state.hourly = payload?.hourlyStatistics?.statistics;
      // }
      // if (!state.continuousWinning.statistics.length) {
      //   state.continuousWinning = payload?.countiniousWining;
      // }
      // if (!state.winRateOverTime[0]?.data?.length) {
      //   state.winRateOverTime = payload?.winRateOverTime?.statistics;
      // }
      state.loading = false;
      state.loaded = true;
    });
    builder.addCase(getStatisticsNew.pending, (state, action) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(getHourlyStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      state.hourly = payload?.hourlyStatistics?.statistics;
      state.hourlyLoading = false;
    });
    builder.addCase(getHourlyStatistics.pending, (state, action) => {
      state.hourlyLoading = true;
    });
    builder.addCase(getContinuousWinStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      state.continuousWinning = payload?.countiniousWining;
      state.continueWinningLoading = false;
    });
    builder.addCase(getContinuousWinStatistics.pending, (state, action) => {
      state.continueWinningLoading = true;
    });
    builder.addCase(getWinRateStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      state.winRateOverTime = payload?.winRateOverTime?.statistics;
      state.winRateLoading = false;
    });
    builder.addCase(getWinRateStatistics.pending, (state, action) => {
      state.winRateLoading = true;
    });
    builder.addCase(getPatternStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      state.patternAnalyze = payload?.paternAnalyzis;
      state.patternLoading = false;
    });
    builder.addCase(getPatternStatistics.pending, (state, action) => {
      state.patternLoading = true;
    });
    builder.addCase(getPositionStatistics.fulfilled, (state, action) => {
      const { payload } = action;
      state.positionAnalyze = payload?.ballStatistics;
      state.ballLoading = false;
    });
    builder.addCase(getPositionStatistics.pending, (state, action) => {
      state.ballLoading = true;
    });
  },
});

export const { reset } = statisticsSlice.actions;
export default statisticsSlice.reducer;
