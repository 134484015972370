import httpClient from '../http-client';
import {
  IGetConfigurationsReq,
  ICreatePickReq,
  ICreateManualPickReq,
  IUpdatePickReq,
  IDeleteManualPickReq,
} from 'store/slices/picks';

const pickApi = {
  getTemplate: () =>
    httpClient().post('api/PickConfiguration/getPickConfigurationTemplates'),
  getConfigurations: (payload: IGetConfigurationsReq) =>
    httpClient().get('api/PickConfiguration/getPickConfigurations', payload),
  getUserPicks: () =>
    httpClient().get('api/Prediction/getUserPicks'),
  createPick: (payload: ICreatePickReq) =>
    httpClient().post('api/PickConfiguration/createPick', payload),
  updatePick: (payload: IUpdatePickReq) =>
    httpClient().post('api/PickConfiguration/updatePick', payload),
  createManualPick: (payload: ICreateManualPickReq) =>
    httpClient().post('api/PickConfiguration/createManualPick', payload),
  deleteManualPick: (payload: IDeleteManualPickReq) =>
    httpClient().post('api/PickConfiguration/deleteManualPick', payload),
};

export default pickApi;
