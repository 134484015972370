import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { toggleMenu } from 'store/slices/global';
import { selectMenu } from 'store/selectors/global';
import { selectUserToken } from 'store/selectors/user';

import LiveTime from 'components/templates/LiveTime';
import LanguageSwitcher from 'components/templates/LanguageSwitcher';
import { notification } from 'antd';
import { BellFilled, CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { ReactComponent as Alarm } from 'assets/icons/alarm-green.svg';

import clsx from 'clsx';
import { AppDispatch } from 'store/index';
import notificationSound from 'assets/records/notification.mp3';
import alarmSound from 'assets/records/alarm.mp3';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  // const screen = useBreakpoint();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const collapsed = useSelector(selectMenu);
  const token = useSelector(selectUserToken);
  const [api, contextHolder] = notification.useNotification();

  const {
    sendMessage,
    lastJsonMessage,
  }: {
    sendMessage: Function;
    lastJsonMessage: {
      Type: string;
      GameName: string;
      MarketName: string;
      Value?: string;
    };
  } = useWebSocket(
    `${process.env.REACT_APP_WEB_SOCKET_URL}?token=${token}` || '',
    {
      onOpen: () => console.log('Socket Connected'),
      shouldReconnect: () => true,
    }
  );
  const nS = new Audio(notificationSound);
  const aS = new Audio(alarmSound);

  const toggleM = () => dispatch(toggleMenu(!collapsed));

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage?.Value === '1') {
        sendMessage('ok');
        return;
      }
      const notificationData: {
        [key: string]: {
          message: { message: ReactNode; icon: ReactNode; duration?: number };
          sound: HTMLAudioElement;
        };
      } = {
        alarm: {
          message: {
            message: (
              <>
                {`${t('pick-alarm-message')} ${t(
                  lastJsonMessage?.GameName?.toLocaleLowerCase()
                    .split(' ')
                    .join('-')
                )} `}
                <Link
                  to={`/picks?scrollToView=${lastJsonMessage?.GameName}-${lastJsonMessage?.MarketName}`}
                  children={`${t('check')}`}
                />
              </>
            ),
            icon: <BellFilled style={{ color: 'green' }} />,
          },
          sound: nS,
        },
        reminder: {
          message: {
            message: (
              <>
                {`${t('reminder-message')} ${t(
                  lastJsonMessage?.GameName?.toLocaleLowerCase()
                    .split(' ')
                    .join('-')
                )} `}
                <Link
                  to={`/picks?scrollToView=${lastJsonMessage?.GameName}-${lastJsonMessage?.MarketName}`}
                  children={`${t('check')}`}
                />
              </>
            ),
            icon: (
              <Alarm
                style={{ width: '18px', height: 'auto', color: 'green' }}
              />
            ),
          },
          sound: aS,
        },
      };

      const notification =
        notificationData[lastJsonMessage?.Type?.toLocaleLowerCase()];
      if (notification) {
        if (!(document.visibilityState === 'visible')) {
          notification.message.duration = 60;
        }
        notification.sound.play();
        api.success(notification.message);
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    sendMessage('Hello');
  }, []);

  return (
    <div className='HeaderMain'>
      <div
        className={clsx(
          'HeaderMain-MenuIcon',
          !collapsed && 'HeaderMain-MenuIcon_rotate'
        )}
      >
        {collapsed ? (
          <MenuOutlined onClick={() => toggleM()} />
        ) : (
          <CloseOutlined onClick={() => toggleM()} />
        )}
      </div>
      <div className='HeaderMain-Time'>
        <LiveTime />
      </div>
      <LanguageSwitcher />
      {contextHolder}
    </div>
  );
};

export default Header;
