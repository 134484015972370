export const getStorageData = (key: string) => {
  const ref = localStorage.getItem(key);
  const storage = ref ? JSON.parse(ref) : null;
  return storage;
};

export const setStorageData = (key: string, data: unknown) => {
  const str = JSON.stringify(data);
  localStorage.setItem(key, str);
};

export const getCurrentLocale = () => {
  const localization = getStorageData('localization');
  return localization?.language || 'KR';
};

export const getCurrentTimezone = () => {
  const data = getStorageData('timezone');
  return +(data?.timezone || '540');
};

