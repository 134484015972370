export const thunkWrapper = async (
  payload: any,
  apiFn: any,
  reject?: Function
) => {
  try {
    const response = await apiFn?.(payload);
    payload?.afterSuccess?.(response);
    return response;
  } catch (err: any) {
    payload?.afterFail?.(err);
    return reject && reject(err);
  }
};
