import httpClient from '../http-client';
import { ISignInReq, IChangePasswordReq, IUpdateNotificationSettingsReq } from 'store/slices/user';

const userApi = {
  // auth
  signIn: (payload: ISignInReq) =>
    httpClient().post('api/Auth/signin', payload),
  signOut: (payload: { refresh: string }) =>
    httpClient().post('api/Auth/signout', payload),
  userInfo: () =>
    httpClient().get('api/User/getuserinfo'),
  changePassword: (payload: IChangePasswordReq) =>
    httpClient().post('api/Auth/updatetemppassword', payload),
  updateNotificationSettings: (payload: IUpdateNotificationSettingsReq) =>
    httpClient().post('api/Configuration/updateNotificationSettings', payload),
};

export default userApi;
