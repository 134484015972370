export const timezones = [
  {
    label: 'UTC -12',
    value: -720,
  },
  {
    label: 'UTC -11',
    value: -660,
  },
  {
    label: 'UTC -10',
    value: -600,
  },
  {
    label: 'UTC -9',
    value: -540,
  },
  {
    label: 'UTC -8',
    value: -480,
  },
  {
    label: 'UTC -7',
    value: -420,
  },
  {
    label: 'UTC -6',
    value: -360,
  },
  {
    label: 'UTC -5',
    value: -300,
  },
  {
    label: 'UTC -4',
    value: -240,
  },
  {
    label: 'UTC -3',
    value: -180,
  },
  {
    label: 'UTC -2',
    value: -120,
  },
  {
    label: 'UTC -1',
    value: -60,
  },
  {
    label: 'UTC 0',
    value: 0,
  },
  {
    label: 'UTC +1',
    value: 60,
  },
  {
    label: 'UTC +2',
    value: 120,
  },
  {
    label: 'UTC +3',
    value: 180,
  },
  {
    label: 'UTC +4',
    value: 240,
  },
  {
    label: 'UTC +5',
    value: 300,
  },
  {
    label: 'UTC +6',
    value: 360,
  },
  {
    label: 'UTC +7',
    value: 420,
  },
  {
    label: 'UTC +8',
    value: 480,
  },
  {
    label: 'UTC +9',
    value: 540,
  },
  {
    label: 'UTC +10',
    value: 600,
  },
  {
    label: 'UTC +11',
    value: 660,
  },
  {
    label: 'UTC +12',
    value: 720,
  },
];

export const dateFilterFields = [
  {
    label: 'today',
    value: 1,
  },
  {
    label: 'yesterday',
    value: 2,
  },
  {
    label: 'last-7-days',
    value: 3,
  },
  {
    label: 'last-30-days',
    value: 4,
  },
];

export const dftM: { [key: string]: number } = {
  '1': 0,
  '2': 1,
  '3': 7,
  '4': 30,
  '5': 90,
};

export const dateFormat = 'MM-DD-YYYY';

export const gameRateLimits: {
  [key: number]: { [key: number]: { min: number; max: number } };
} = {
  1: { 24: { min: 16.6, max: 99 } },
  2: { 27: { min: 25, max: 99 } },
};
