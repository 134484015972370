import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'store/services/user';
import { thunkWrapper } from 'store/thunk';
import { AppDispatch } from '..';

const userRole: { [key: string]: string } = {
  Administrator: 'admin',
  User: 'user',
};

export interface IUserInfoRes {
  username: string;
  role: string;
  notificationSettings: {
    pickAlarmEnabled: boolean;
    reminderEnabled: boolean;
  };
}
export interface ISignInReq {
  userName: string;
  password: string;
}
export interface ISignInRes {
  token: string;
  changePasswordToken: string;
  message?: string;
}
export interface IChangePasswordReq {
  userName: string;
  password: string;
  confirmPassword: string;
  passwordToken: string;
}

export interface ICurrentMessage {
  success: string;
  error: string;
}

export interface IUpdateNotificationSettingsReq {
  pickAlarmEnabled: boolean;
  reminderEnabled: boolean;
}

const initialState: {
  token: string | null;
  changePasswordToken: string | null;
  profile: {
    userName: string;
    role: string;
    notificationSettings: {
      pickAlarmEnabled: boolean;
      reminderEnabled: boolean;
    };
  };
  loading: boolean;
  message: string | undefined;
  current: {
    success: boolean;
    loading: boolean;
    message: ICurrentMessage;
  };
} = {
  token: null,
  changePasswordToken: null,
  profile: {
    userName: '',
    role: '',
    notificationSettings: { pickAlarmEnabled: false, reminderEnabled: false },
  },
  loading: false,
  message: '',
  current: {
    success: false,
    loading: false,
    message: {
      success: '',
      error: '',
    },
  },
};

export const userSignIn = createAsyncThunk<
  ISignInRes,
  ISignInReq,
  { dispatch: AppDispatch; rejectValue: { message: string } }
>('user/signIn', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, userApi.signIn, rejectWithValue)
);

export const userSignOut = createAsyncThunk<
  null,
  { userName: string },
  { dispatch: AppDispatch }
>('user/signOut', (payload) => thunkWrapper(payload, userApi.signOut));

export const userInfo = createAsyncThunk<
  IUserInfoRes,
  null,
  { dispatch: AppDispatch }
>('user/info', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, userApi.userInfo, rejectWithValue)
);

export const userChangePassword = createAsyncThunk<
  IChangePasswordReq,
  unknown,
  { dispatch: AppDispatch }
>('user/changePassword', (payload) =>
  thunkWrapper(payload, userApi.changePassword)
);

export const updateNotificationSettings = createAsyncThunk<
  unknown,
  IUpdateNotificationSettingsReq,
  { dispatch: AppDispatch }
>('notification/settings', (payload, { rejectWithValue }) =>
  thunkWrapper(payload, userApi.updateNotificationSettings, rejectWithValue)
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPasswordChangeInfo: (state, action) => {
      const { payload } = action;
      state.profile.userName = payload.userName;
    },
    reset: () => {
      return initialState;
    },
    setCurrentMessages: (state, action) => {
      const { payload } = action;
      state.current.message.success = payload?.success;
      state.current.message.error = payload?.error;
    },
    resetCurrent: (state) => {
      state.current = {
        success: false,
        loading: false,
        message: {
          success: '',
          error: '',
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSignIn.fulfilled, (state, action) => {
      const { payload } = action;
      state.token = payload?.token;
      state.changePasswordToken = payload?.changePasswordToken;
      state.loading = false;
    });
    builder.addCase(userSignIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userSignIn.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.message = payload?.message;
    });
    builder.addCase(userSignOut.fulfilled, () => {
      return initialState;
    });
    builder.addCase(userInfo.fulfilled, (state, action) => {
      const { payload } = action;
      state.profile.userName = payload?.username;
      state.profile.role = userRole[payload?.role];
      state.profile.notificationSettings.pickAlarmEnabled = payload?.notificationSettings?.pickAlarmEnabled;
      state.profile.notificationSettings.reminderEnabled = payload?.notificationSettings?.reminderEnabled;
    });
    builder.addCase(userInfo.rejected, (state) => {
      return initialState;
    });
    builder.addCase(userChangePassword.fulfilled, () => {
      return initialState;
    });
    builder.addCase(userChangePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateNotificationSettings.fulfilled, (state) => {
      state.current.loading = false;
      state.current.success = true;
    });
    builder.addCase(updateNotificationSettings.pending, (state) => {
      state.current.loading = true;
    });
    builder.addCase(updateNotificationSettings.rejected, (state) => {
      state.current.loading = false;
    });
  },
});

export const {
  setPasswordChangeInfo,
  reset,
  setCurrentMessages,
  resetCurrent,
} = userSlice.actions;

export default userSlice.reducer;
