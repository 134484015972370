import httpClient from '../http-client';
import {
  IPickHistoryReq,
  IUpdateAvailabilityReq,
} from 'store/slices/pickHistory';
const pickHistoryApi = {
  get: (payload: IPickHistoryReq) =>
    httpClient().post('api/prediction/getUserPicksHistory', payload),
  delete: (payload: IPickHistoryReq) =>
    httpClient().post('api/prediction/deleteUserPick', payload),
  updateAvailability: (payload: IUpdateAvailabilityReq) =>
    httpClient().post('api/prediction/setUserPickHistoryAvailable', payload),
};

export default pickHistoryApi;
