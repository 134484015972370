import { createSlice } from '@reduxjs/toolkit';
import { getCurrentLocale, getCurrentTimezone, setStorageData } from 'utils/localStorage';
// const global = localStorage.getItem('global');
const initialState = {
  menuOpened: true,
  modal: { open: false },
  localization: getCurrentLocale(),
  timezone: getCurrentTimezone(),
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      const { payload } = action;
      state.menuOpened = payload;
    },
    toggleModal: (state, action) => {
      const { payload } = action;
      state.modal = payload?.open ? payload : initialState.modal;
    },
    setLocalization: (state, action) => {
      const { payload } = action;
      setStorageData('localization', { language: payload });
      state.localization = payload;
    },
    setTimezone: (state, action) => {
      const { payload } = action;
      setStorageData('timezone', { timezone: payload });
      state.timezone = payload;
    },
  },
});

export const { toggleMenu, toggleModal, setLocalization, setTimezone } = globalSlice.actions;
export default globalSlice.reducer;
