import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { loadComponent } from 'components/organisms/LoadComponent';
import { PATHS } from './paths';
import i18n from 'localization/index';
// auth components
const SignIn = loadComponent(() => import('components/pages/Auth/SignIn'));
const ChangePassword = loadComponent(() => import('components/pages/Auth/ChangePassword'));
// const SignUp = loadComponent(() => import("components/pages/Auth/SignUp"));
// const SignOut = loadComponent(() => import("components/pages/Auth/SignOut"));
// const ForgotPassword = loadComponent(() => import("components/pages/Auth/ForgotPassword"));
// const ResetPassword = loadComponent(() => import("components/pages/Auth/ResetPassword"));
// const ConfirmEmailUpdate = loadComponent(() => import("components/pages/Auth/ConfirmEmailUpdate"));
// const VerifyEmail = loadComponent(() => import("components/pages/Auth/VerifyEmail"));

const Dashboard = loadComponent(
  () => import('components/pages/Dashboard/Dashboard')
);
const Speed_6 = loadComponent(
  () => import('components/pages/Statistics/Speed_6')
);
const Speed_4 = loadComponent(
  () => import('components/pages/Statistics/Speed_4')
);
const Maze_2 = loadComponent(
  () => import('components/pages/Statistics/Maze_2')
);
const Picks = loadComponent(() => import('components/pages/Picks/Picks'));
const Users = loadComponent(() => import('components/pages/Users/Users'));
const PickSettings = loadComponent(
  () => import('components/pages/PickSettings/PickSettings')
);
const PickHistory = loadComponent(() => import('components/pages/PickHistory/PickHistory'));
const Calculator = loadComponent(() => import('components/pages/Calculator/Calculator'));

export interface IRoutes {
  index: string;
  label: string;
  path: string;
  isMenuItem?: boolean;
  role?: string;
  icon?: React.ReactNode;
  element: FC;
  subRoutes?: IRoutes[];
  className?: string;
}

const routeWrapper = ({
  condition,
  component,
  pathToRedirect = PATHS.SIGN_IN,
  originalPath,
}: {
  condition: boolean;
  component: FC;
  pathToRedirect: string;
  originalPath?: string;
}) => {
  if (condition) {
    return component;
  } else {
    const search = originalPath ? `?from=${originalPath?.substring(1)}` : null;
    return () => (
      <Navigate
        to={{ pathname: pathToRedirect }}
        state={search}
        replace={true}
      />
    );
  }
};

export const getRoutes = (isAuth: boolean, uRole: string, t?: Function): IRoutes[] => {
  return [
    {
      index: PATHS.DASHBOARD,
      label: i18n.t('dashboard'),
      path: PATHS.DASHBOARD,
      isMenuItem: true,
      // role: 'user',
      element: routeWrapper({
        condition: isAuth,
        component: Dashboard,
        originalPath: PATHS.DASHBOARD,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: PATHS.STATISTICS,
      label: i18n.t('statistics'),
      path: PATHS.STATISTICS,
      isMenuItem: true,
      // role: 'user',
      element: routeWrapper({
        condition: false,
        component: () => <></>,
        originalPath: PATHS.STATISTICS,
        pathToRedirect: PATHS.STATISTICS_SPEED_6,
      }),
      subRoutes: [
        {
          index: PATHS.STATISTICS_SPEED_6,
          label: i18n.t('speed-6'),
          path: PATHS.STATISTICS_SPEED_6,
          isMenuItem: true,
          // role: 'user',
          element: routeWrapper({
            condition: isAuth,
            component: Speed_6,
            originalPath: PATHS.STATISTICS_SPEED_6,
            pathToRedirect: PATHS.SIGN_IN,
          }),
          subRoutes: [],
        },
        {
          index: PATHS.STATISTICS_SPEED_4,
          label: i18n.t('speed-4'),
          path: PATHS.STATISTICS_SPEED_4,
          isMenuItem: true,
          // role: 'user',
          element: routeWrapper({
            condition: isAuth,
            component: Speed_4,
            originalPath: PATHS.STATISTICS_SPEED_4,
            pathToRedirect: PATHS.SIGN_IN,
          }),
          subRoutes: [],
        },
        {
          index: PATHS.STATISTICS_MAZE_2,
          label: i18n.t('maze-2'),
          path: PATHS.STATISTICS_MAZE_2,
          isMenuItem: true,
          // role: 'user',
          element: routeWrapper({
            condition: isAuth,
            component: Maze_2,
            originalPath: PATHS.STATISTICS_MAZE_2,
            pathToRedirect: PATHS.SIGN_IN,
          }),
          subRoutes: [],
        },
      ],
    },
    {
      index: PATHS.PICKS,
      label: i18n.t('picks'),
      path: PATHS.PICKS,
      isMenuItem: true,
      // role: 'user',
      element: routeWrapper({
        condition: isAuth,
        component: Picks,
        originalPath: PATHS.PICKS,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: PATHS.USERS,
      label: i18n.t('users'),
      path: PATHS.USERS,
      isMenuItem: true,
      role: 'admin',
      element: routeWrapper({
        condition: isAuth,
        component: Users,
        originalPath: PATHS.USERS,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: PATHS.PICK_SETTINGS,
      label: i18n.t('pick-settings'),
      path: PATHS.PICK_SETTINGS,
      isMenuItem: true,
      role: 'admin',
      element: routeWrapper({
        condition: isAuth,
        component: PickSettings,
        originalPath: PATHS.PICK_SETTINGS,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: PATHS.PICK_HISTORY,
      label: i18n.t('pick-history'),
      path: PATHS.PICK_HISTORY,
      isMenuItem: true,
      // role: 'admin',
      element: routeWrapper({
        condition: isAuth,
        component: PickHistory,
        originalPath: PATHS.PICK_HISTORY,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: PATHS.CALCULATOR,
      label: i18n.t('calculator'),
      path: PATHS.CALCULATOR,
      isMenuItem: true,
      // role: '',
      element: routeWrapper({
        condition: isAuth,
        component: Calculator,
        originalPath: PATHS.CALCULATOR,
        pathToRedirect: PATHS.SIGN_IN,
      }),
      subRoutes: [],
    },
    {
      index: 'logout',
      label: i18n.t('logout'),
      path: PATHS.DASHBOARD,
      isMenuItem: true,
      // icon: <LogoutOutlined />,
      className: 'LogOut-Icon',
      element: () => <></>,
    },
  ].filter((r) => !r.role || r.role === uRole);
};

export const getAuthRoutes = (isAuth: boolean): IRoutes[] => {
  return [
    // {
    //   label: 'Sign up',
    //   path: PATHS.SIGN_UP,
    //   element: routeWrapper({
    //     condition: !isAuth,
    //     component: SignUp,
    //     pathToRedirect: PATHS.BROWSE,
    //   }),
    // },
    {
      index: PATHS.SIGN_IN,
      label: 'Sign in',
      path: PATHS.SIGN_IN,
      element: routeWrapper({
        condition: !isAuth,
        component: SignIn,
        originalPath: PATHS.SIGN_IN,
        pathToRedirect: PATHS.DASHBOARD,
      }),
    },
    {
      index: PATHS.SIGN_IN_ADMIN,
      label: 'Sign in',
      path: PATHS.SIGN_IN_ADMIN,
      role: 'admin',
      element: routeWrapper({
        condition: !isAuth,
        component: SignIn,
        originalPath: PATHS.SIGN_IN_ADMIN,
        pathToRedirect: `${PATHS.ADMIN_ROOT}${PATHS.DASHBOARD}`,
      }),
    },
    {
      index: PATHS.CHANGE_PASSWORD,
      label: 'Sign in',
      path: PATHS.CHANGE_PASSWORD,
      element: routeWrapper({
        condition: !isAuth,
        component: ChangePassword,
        originalPath: PATHS.CHANGE_PASSWORD,
        pathToRedirect: PATHS.DASHBOARD,
      }),
    },
    // {
    //   label: 'Forgot password',
    //   path: PATHS.FORGOT_PASSWORD,
    //   element: routeWrapper({
    //     condition: !isAuth,
    //     component: ForgotPassword,
    //     pathToRedirect: PATHS.BROWSE,
    //   }),
    // },
    // {
    //   label: 'RESET_PASSWORD',
    //   path: PATHS.RESET_PASSWORD,
    //   element: routeWrapper({
    //     condition: !isAuth,
    //     component: ResetPassword,
    //     pathToRedirect: PATHS.BROWSE,
    //   }),
    // },
    // {
    //   label: 'RESET_PASSWORD',
    //   path: PATHS.CONFIRM_EMAIL_UPDATE,
    //   element: ConfirmEmailUpdate,
    // },
    // {
    //   label: 'VERIFY_EMAIL',
    //   path: PATHS.VERIFY_EMAIL,
    //   element: VerifyEmail,
    // },
    // {
    //   label: 'Sign out',
    //   path: PATHS.SIGN_OUT,
    //   element: routeWrapper({ condition: isAuth, component: SignOut }),
    // },
  ];
};
