export const PATHS = {
  ROOT: '/',
  ADMIN_ROOT: '/admin',
  // AUTH ROUTES
  AUTH: '/auth',
  SIGN_IN: '/auth/sign-in',
  SIGN_IN_ADMIN: '/auth/sign-in-admin',
  CHANGE_PASSWORD: '/auth/change-password',
  

  DASHBOARD: '/dashboard',
  STATISTICS: '/statistics',
  STATISTICS_SPEED_6: '/statistics/speed-6',
  STATISTICS_SPEED_4: '/statistics/speed-4',
  STATISTICS_MAZE_2: '/statistics/maze-2',
  PICKS: '/picks',
  PICK_SETTINGS: '/pick-settings',
  PICK_HISTORY: '/pick-history',
  CALCULATOR: '/calculator',
  USERS: '/users'
};
