import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentLocale } from "utils/localStorage";
// translations
import hy from "./hy.json";
import kr from "./ko.json";
import en from "./en.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: { en, kr, hy },
    lowerCaseLng: true,
    fallbackLng: "en",
    lng: getCurrentLocale() || "en",
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
