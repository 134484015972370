import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'store/slices/global';
import { selectModal } from 'store/selectors/global';
import { Modal } from 'antd';

const ModalM: React.FC = () => { 
  const dispatch = useDispatch();
  const { open, title, content, closeIcon = true } = useSelector(selectModal);
  const handleCancel = () => dispatch(toggleModal({ open: false }));

  return(
  <Modal
    className='Modal'
    open={open}
    title={title}
    // onOk={handleOk}
    onCancel={handleCancel}
    closeIcon={closeIcon}
    footer={(_, { OkBtn, CancelBtn }) => (
      <>
        {/* <Button>Custom Button</Button>
        <CancelBtn />
        <OkBtn /> */}
      </>
    )}
  >
    {content}
  </Modal>
)};

export default ModalM;
