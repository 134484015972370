import { useLayoutEffect, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { getAuthRoutes, getRoutes, IRoutes } from 'constants/routes';
import { PATHS } from 'constants/paths';
import { selectUserToken, selectUser } from 'store/selectors/user';
import { selectLocalization } from 'store/selectors/global';
import AuthLayout from 'components/organisms/AuthLayout';
import MainLayout from 'components/organisms/MainLayout';
import { loadComponent } from 'components/organisms/LoadComponent';
import { AppDispatch } from './store';
import { userInfo } from 'store/slices/user';
// import { initNotifications } from "utils/pushNotifications";
import i18n from 'localization';

const Page404 = loadComponent(() => import('components/pages/404'));

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const userToken = useSelector(selectUserToken);
  const profile = useSelector(selectUser);
  const localization = useSelector(selectLocalization);
  const userRole = profile?.role;
  const getUser = () => dispatch(userInfo(null));

  const extract = (routes: IRoutes[]): IRoutes[] => {
    let routeComp: IRoutes[] = [];
    routes.forEach((route) => {
      if (route.subRoutes?.length) {
        routes = extract(route.subRoutes);
      }
      if (route.path) {
        routeComp.push(route);
      }
    });
    return [...routeComp, ...routes];
  };

  const AllRoutes = useMemo(() => {
    const isAuthed = !!userToken;
    const routes = getRoutes(isAuthed, userRole);
    const defRoutes = extract(routes);
    return defRoutes.map(({ path, element: Element }) => (
      <Route key={path} path={path} element={<Element />} />
    ));
  }, [userToken, userRole]);

  const AuthRoutes = useMemo(() => {
    const isAuthed = !!userToken;
    const routes = getAuthRoutes(isAuthed);
    return routes.map(({ path, element: Element }) => (
      <Route key={path} path={path} element={<Element />} />
    ));
  }, [userToken, userRole]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    userToken && getUser();
  }, [userToken]);

  useEffect(() => {
    i18n.changeLanguage(localization);
  }, [localization]);

  // useEffect(() => {
  //   getUser();
  // }, []);

  return (
    <Routes>
      <Route path='/' element={<Navigate to={PATHS.DASHBOARD} />} />
      <Route path={PATHS.ROOT} element={<MainLayout />} children={AllRoutes} />
      <Route path={PATHS.AUTH} element={<AuthLayout />} children={AuthRoutes} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};

export default App;
