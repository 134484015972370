import axios from "axios";
import { store } from "store";
import { selectUserToken } from "./selectors/user";
const { REACT_APP_API_URL } = process.env;

const client = () => {
  const state = store.getState();
  const token = selectUserToken(state);
  const service = axios.create({ baseURL: REACT_APP_API_URL, headers: token ? { Authorization: `Bearer ${token}` } : {} });

  service.interceptors.response.use(
    (response) => response.data,
    (error) => {
      const response = error?.response?.data;
      // if (error?.response?.status === 401) {
      //   window.location.pathname = "/auth/sign-in";
      // }
      return Promise.reject(response);
    }
  );
  return service;
};

export default client;
