import Header from '../Header';
import Menu from '../Menu';
import Modal from '../Modal';
import { Outlet } from 'react-router-dom';

// import Footer from "../Footer";

const MainLayout: React.FC = () => {
  return (
    <div className='MainLayout'>
      <Menu />
      <main className='MainLayout-Body'>
        <Header />
        <div className='MainLayout-Content'>
          <Outlet />
        </div>
      </main>
      <Modal />
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
