import { Suspense } from "react";
import loadable from "@loadable/component";

export const loadComponent = (importFn: any, opts?: any) => {
  const Component = loadable(importFn, opts);
  return (props: {}) => {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };
};
