import httpClient from '../http-client';
import { IStatisticsReq, IStatisticsNewReq } from 'store/slices/statistics';

const statisticsApi = {
  get: (payload: IStatisticsReq) =>
    httpClient().post('api/Statistics/getStatistics', payload),
  getNew: (payload: IStatisticsNewReq) =>
    httpClient().post('api/advancedStatistics/getAdvancedStatistics', payload),
  getHourly: (payload: IStatisticsNewReq) =>
    httpClient().post('api/advancedStatistics/getHourlyStatistics', payload),
  getContinuousWin: (payload: IStatisticsNewReq) =>
    httpClient().post('api/advancedStatistics/getContinousStatistics', payload),
  getWinRate: (payload: IStatisticsNewReq) =>
    httpClient().post(
      'api/advancedStatistics/getWinOverTimeStatistics',
      payload
    ),
  getPatterns: (payload: IStatisticsNewReq) =>
    httpClient().post('api/advancedStatistics/getPatternAnalyzes', payload),
  getPositions: (payload: IStatisticsNewReq) =>
    httpClient().post('api/advancedStatistics/getBallStatistics', payload),
};

export default statisticsApi;
